import * as React from 'react';
import { createStore, Provider } from 'jotai';

import { menuAtom, videoAtom, brandsAtom, locationsAtom } from '~/atoms';

const store = createStore();
store.set(menuAtom, false);
store.set(videoAtom, true);
store.set(brandsAtom, false);
store.set(locationsAtom, false);

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};

export const wrapPageElement = ({ element }) => {
  return <>{element}</>;
};
