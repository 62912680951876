import './src/styles/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ReactGA from 'react-ga4';

export const onClientEntry = () => {
  ReactGA.initialize('G-NXMT5QNXPH');
};

export { wrapRootElement, wrapPageElement } from './gatsby-shared';
