import { atom } from 'jotai';

export const menuAtom = atom<boolean>(false);

export const videoAtom = atom<boolean>(true);

export const muteAtom = atom<boolean>(true);

export const brandsAtom = atom<boolean>(false);

export const locationsAtom = atom<boolean>(false);

export const scrollAtom = atom<boolean>(false);
